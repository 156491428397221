import Icon from "@/components/global/icon/Icon";
import { CardWithTitle } from "../../case_details/components/cards/DetailsCards";
import { Empty } from "antd";
import { Separator } from "@/components/ui/separator";


const VitalsAlarm = ({alarms}: any) => {
    return (
        <div className="px-2 pb-2">
        <CardWithTitle
            // alertClasses={`${alertGlow && alertAnimationClasses}`}
            title={<div className="flex items-center justify-center">
                <Icon icon='ri:alert-line' color='#161B1F' fontSize={20}/>
                <div className="ml-3 text-sm font-semibold"> {"Vital Alarms"} </div>
                </div>}
            className={'text-center w-full'}
            styles={{
                backgroundColor: '#F7F7F7',
                height: '50px'
                
            }}>
                <div>
                    {alarms?.length > 0 ? (
                        <div className="max-h-28 h-28 overflow-scroll px-2 py-2">
                            {alarms.map((entry: any)=> {
                                return (
                                    <div>
                                    <div className="flex justify-start align-start text-start text-black text-xs text-red-600 py-1">
                                        <div className="min-w-[40%]">{entry?.timestamp}</div>
                                        <div className="min-w-[40%]">{entry?.message}</div>
                                        <div>{entry?.priority}</div>
                                    </div>
                                    <Separator />
                                    </div>
                                )
                            })}
                        </div>
                    ): (
                        <div className="py-1">
                            <Empty description="No active alerts" imageStyle={{height: 60}}/>
                        </div>
                    )}
                </div>
            </CardWithTitle>
            </div>
    )
}

export default VitalsAlarm;